import React, { useState, useEffect } from 'react'
import '../styles/styleMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faPen, faXmark, faCheck, faChevronRight, faCircleCheck, faSpinner, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Moment from 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../models/config';

const Profile = () => {

    const [newIP, setNewIP] = useState('');
    const [newHostIP, setnewHostIP] = useState('');
    const [open, setOpen] = useState(false)
    const [openEditIp, setOpenEditIp] = useState({});
    const [found, setFound] = useState(false)
    const [modelsNewAllIP, setModelsNewAllIP] = useState(false)
    const [modelsNewIPScript, setModelsNewIPScript] = useState(false)
    const [loading, setLoading] = useState(false);
    const [people, setPeople] = useState([]);
    const data = useSelector((state) => state);
    const [indexScript, setIndexScript] = useState(0);


    useEffect(() => {
        getScript()
        const check = document.querySelector('select');
        if(check) {
            return;
        }
    }, []);

    const getScript = () => {
        const API = `${config.URL}/getAllScript`
        setLoading(true);
        axios.post(API, {
            "clientID": data.clientID
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const setScript = JSON.parse(script.scripts)

                const listresponse = setScript.map(
                    (item, i) => {
                        const setdata = Moment(item.date, "DD-MM-yyyyTHH:mm:ss.SSSZ").tz("Asia/Bangkok").format("DD-MM-YYYY");
                        console.log('%c⧭', 'color: #ffa280', setdata);
                        return {
                            ...item,
                            date: setdata
                        };
                    }
                );
                setLoading(false);
                setPeople(listresponse);

            }).catch((error) => {
                setFound(true)
                setLoading(false);
            });
    }


    const changeHostIp = (e) => {
        const newHostIp = e.target.value;
        const filteredHostIp = newHostIp.replace(/[^\d.]/g, '');
        setnewHostIP(filteredHostIp);
    }

    const changeNewIP = (e, index) => {
        const newIP = e.target.value;
        const filteredHostIp = newIP.replace(/[^\d.]/g, '');
        setNewIP({
            [index]: filteredHostIp,
        });
    };

    const editHostIP = () => {
        setOpen(true)
        setOpenEditIp(false)
    }

    const changeAllIP = () => {
        if (!newHostIP) {
            return
        } else {
            setModelsNewAllIP(true)
            setOpen(false)
        }
    }
    const changeNewAllIP = async () => {
        setModelsNewAllIP(false)
        setnewHostIP('')
        setLoading(true);
        let listScripts = []
        people.map((person) => {
            listScripts.push({
                "script": person.script,
                "ip": newHostIP
            })
        });
        const API = `${config.URL}/changeIP`

        await axios.post(API, {
            "clientID": data.clientID,
            "access_token": data.accessToken,
            "listScripts": listScripts
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const listScripts = script.listScripts

                const listresponse = listScripts.map(
                    (item, i) => {
                        const setdata = Moment(item.date, "yyyy-MM-DDTHH:mm:ss.SSSZ").tz("Asia/Bangkok").format("DD-MM-YYYY");
                        return {
                            ...item,
                            date: setdata
                        };
                    }
                );
                setPeople(listresponse);
                setLoading(false);
                toast.success("เปลี่ยน IP สำเร็จ")

            }).catch((error) => {
                setLoading(false);
            });
    }

    const cancel = () => {
        setOpen(false)
        setnewHostIP('')
    }
    const cancelModelsNewAllIP = () => {
        setOpen(true)
        setModelsNewAllIP(false)
    }
    const cancelModelsNewIPScript = () => {
        setModelsNewIPScript(false)
    }

    const editIP = (index) => {
        setOpenEditIp((prevOpenEditIp) => ({
            ...prevOpenEditIp,
            [index]: true,
        }));
    };

    const confirmEditIP = (index) => {
        setIndexScript(index)
        if (!newIP) {
            toast.warn("กรุณากรอก IP")
            return
        } else {
            setModelsNewIPScript(true)
        }

    }

    const changeIPNewIPScript = () => {
        const updatedPeople = people.map((person, i) => {
            if (i === indexScript) {
                return { ...person, ip: newIP[indexScript] };
            }
            return person;
        });

        let listScripts = [
            {
                "script": updatedPeople[indexScript].script,
                "ip": updatedPeople[indexScript].ip
            }
        ]

        setNewIP('')
        setModelsNewIPScript(false)
        setOpenEditIp(false)
        setLoading(true);

        const API = `${config.URL}/changeIP`

        axios.post(API, {
            "clientID": data.clientID,
            "access_token": data.accessToken,
            "listScripts": listScripts
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const Name = script.listScripts[0].script
                const NewIP = script.listScripts[0].ip
                const oldip = script.listScripts[0].oldip

                const listresponse = people.map((person) => {
                    if (person.script === Name && person.ip === oldip) {
                        return { ...person, ip: NewIP };
                    }
                    return person;
                });

                setPeople(listresponse);
                setLoading(false);
                toast.success("เปลี่ยน IP สำเร็จ")

            }).catch((error) => {
                setLoading(false);
            });
    }

    const cancelEditIP = (index) => {
        setOpenEditIp((prevOpenEditIp) => ({
            ...prevOpenEditIp,
            [index]: false,
        }));
        setNewIP('')
    }


    return (
        <div className="from-profile pt-[10vw] px-[25vw] w-[100%]">
            {found ? (
                <div className="text-center">
                    <img className='img-found mr-[auto] ml-[auto] w-[50%]'
                        src="https://media.discordapp.net/attachments/796649426214977547/1133717293717798932/Lovepik_com-832483587-25D_style_404_error_gradient_art_word_can_be_commercial_elements.png?width=671&height=671" alt="" />
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
                    <p className="mt-6 text-base leading-7 text-white">Sorry, we couldn’t find the page you’re looking for.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a href="/" className="text-sm text-yellow-500 font-bold flex gap-[10px] items-center hover:bg-orange-300 hover:text-white delay-75 p-[10px] rounded-[10px] backdrop-blur-sm bg-white/20">
                            Go back home
                            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                        </a>
                    </div>
                </div>
            ) : (
                <>
                    {loading && (
                        <div className='fixed gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50 text-white'>
                            <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin'></FontAwesomeIcon>
                            Load...
                        </div>
                    )}
                    <div className='host-ip text-white flex gap-[10px] items-center justify-end'>
                        <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
                        <p className='pt-[5px]'>IP Server : </p>
                        <button type="" className='p-[5px] text-white text-[13px] font-bold bg-gradient-to-r from-sky-500 to-indigo-500 rounded-[5px] hover:scale-[.98] transition ease-in-out'
                            onClick={editHostIP}>เปลี่ยนทั้งหมด</button>
                    </div>
                    {
                        open && (
                            <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                                <div className='card-models absolute grid place-items-center top-[40%] left-[40%] bg-gradient-to-r from-sky-500 to-indigo-500 p-[30px] px-[80px] rounded-[10px] ease-in-out'>
                                    <FontAwesomeIcon icon={faCircleQuestion} className='icon-warm text-[50px] text-yellow-500 animate-bounce'></FontAwesomeIcon>
                                    <p className='font-bold text-white pt-[10px]'>IP Server</p>
                                    <input
                                        type="text"
                                        name="ip"
                                        value={newHostIP}
                                        onChange={changeHostIp}
                                        className='border-2 border-gray-600 bg-black backdrop-blur-sm bg-white/20 text-white font-bold rounded-[5px] focus:outline-none pl-[5px] text-[13px] p-[2px] pt-[5px] mt-[10px]' />
                                    <div className='flex mt-[15px] gap-[20px]'>
                                        <button type="" className='btn-models p-[5px] text-white bg-gradient-to-r from-green-500 to-green-700 px-[10px] rounded-[10px] hover:scale-[.9] transition ease-in-out'
                                            onClick={changeAllIP}>เปลี่ยนทั้งหมด</button>
                                        <button type="" className='btn-models p-[5px] text-white bg-gradient-to-r from-red-500 to-red-900 px-[10px] rounded-[10px] hover:scale-[.9] transition ease-in-out'
                                            onClick={cancel}>ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {modelsNewAllIP && (
                        <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                            <div className='card-models absolute grid place-items-center top-[40%] left-[40%] bg-gradient-to-r from-sky-500 to-indigo-500 p-[30px] px-[50px] rounded-[10px] ease-in-out'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-[50px] text-green-500 animate-bounce'></FontAwesomeIcon>
                                <p className='font-bold text-white pt-[10px]'>ยืนยันการเปลี่ยน IP Server หรือไม่?</p>
                                <div className='flex mt-[10px] gap-[10px]'>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-green-500 to-green-700 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={changeNewAllIP}
                                    >ตกลง</button>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-red-500 to-red-900 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={cancelModelsNewAllIP}
                                    >ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {modelsNewIPScript && (
                        <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                            <div className='card-models absolute grid place-items-center top-[40%] left-[40%] bg-gradient-to-r from-sky-500 to-indigo-500 p-[30px] px-[50px] rounded-[10px] ease-in-out'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-[50px] text-green-500 animate-bounce'></FontAwesomeIcon>
                                <p className='font-bold text-white pt-[10px]'>ยืนยันการเปลี่ยน IP Script หรือไม่?</p>
                                <div className='flex mt-[10px] gap-[10px]'>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-green-500 to-green-700 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={changeIPNewIPScript}
                                    >ตกลง</button>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-red-500 to-red-900 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={cancelModelsNewIPScript}
                                    >ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='teble p-[20px]'>
                        <div className='flex justify-center items-center text-white bg-white backdrop-blur-sm bg-white/20 p-[10px] rounded-[10px]'>
                            <div className="basis-1/4"><p>วันที่ซื้อ</p></div>
                            <div className="basis-1/4"><p>ชื่อ</p></div>
                            <div className="text-ip basis-1/3 text-right"><p>IP Server</p></div>
                        </div>
                        <div className='relative h-[70%]'>
                            <ul role="list" className="list-scrip h-[500px] overflow-auto divide-y divide-gray-100 bg-white backdrop-blur-sm bg-white/20 rounded-[10px] mt-[10px] m-[10px]">
                                {people.map((person, index) => (
                                    <li key={index} className="flex justify-between items-center gap-x-6 py-5 px-[50px]">
                                        <p className="date-scrip basis-1/4 truncate text-xs leading-5 text-white">{person.date}</p>
                                        <div className="basis-1/3 min-w-0 flex-auto">
                                            <p className="name-scrip text-sm font-semibold leading-6 text-white">{person.script}</p>
                                        </div>
                                        {openEditIp[index] ? (
                                            <div className="basis-1/1 flex justify-end gap-[10px] items-center">
                                                <form className="">
                                                    <input
                                                        type="text"
                                                        name="IP"
                                                        value={newIP[index] || ''}
                                                        onChange={(e) => changeNewIP(e, index)}
                                                        placeholder='New IP'
                                                        className="rounded-[5px] text-black focus:outline-none w-[120px] px-[5px] text-[15px] pt-[4px]"
                                                    />
                                                </form>
                                                <FontAwesomeIcon
                                                    onClick={() => confirmEditIP(index)}
                                                    icon={faCheck}
                                                    className="cursor-pointer text-[13px] text-white"
                                                ></FontAwesomeIcon>
                                                <FontAwesomeIcon
                                                    onClick={() => cancelEditIP(index)}
                                                    icon={faXmark}
                                                    className="cursor-pointer text-[15px] text-white"
                                                ></FontAwesomeIcon>
                                            </div>
                                        ) : (
                                            <div className="basis-1/1 flex justify-end gap-[10px] items-center">
                                                <p className="ip-scrip text-sm leading-6 text-white">{person.ip}</p>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className="icon-pen cursor-pointer text-[13px] text-white"
                                                    onClick={() => editIP(index)}
                                                ></FontAwesomeIcon>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                    <ToastContainer theme="dark" />
                </>
            )}

        </div >
    )
}


export default Profile