import React, { Component } from 'react'
import Navber from '../components/Navbar'
import { Outlet } from 'react-router-dom'

export default class LayoutNavbar extends Component {
    render() {
        return (
            <>
                <Navber />
                <Outlet />
            </>
        )
    }
}
