//*NOTE |  videoId : เอามาจากลิ้ง youtube ที่ปุ่มแชร์ https://youtu.be/ocDDLPLKe70 -> videoId : ocDDLPLKe70

let listScripts = {
    all: [ //*NOTE รายการสินค้าทั่งหมด
        {
            label: "Economy",
            name: '💸economy💸',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/ECONOMY.jpg?alt=media&token=e8362eb8-1192-4560-9543-7a26a4c27069',
            details: 'ระบบเศรษฐกิจ ค่าเงินแปลงตามการขาย และสามารถปรับแต่ง UI ได้เองตามที่ทางเซิฟต้องการ\nสามารถตั้ง limit / max ของราคาสินค้าได้\nสามารถ Search หาสินค้าเองได้\nราคาสินค้าขึ้นลงตามตลาด [แอดมิน สามารถควบคุมตลาดได้]\nระบบลื่นและกินฝั่ง server น้อยสุดๆ 0.01',
            price: 1500,
            videoId: 'tSjdBTsZOP4'
        },
        {
            label: "Bounty Hunt",
            name: '💀bounty hunt 💀',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/bounty.png?alt=media&token=80f6a7d1-5c41-4672-bd48-c07e3877b3b4',
            details: 'ระบบล่าค่าหัว สุ่มผู้เล่นที่ออนไลน์ให้มีค่าหัว โดยจะมี marker และ blip ขึ้นบนแมพ จะมีรางวัลให้แก่ผู้รอดชีวิต\nสามารถตั้งสุ่มเวลาเริ่มกิจกรรมได้ / สามารถพิมพ์คำสั่งเริ่มกิจกรรมได้\nสามารถกำหนด อาชีพ | โซน ที่จะไม่โดนสุ่มได้\nสามารถเปลี่ยนเสียง และ รูป ได้ด้วยตัวเอง\nเมื่ออกเกมเข้ามาใหม่ยังสามารถเล่นกิจกรรมได้อยู่\nสามารถ เปิด/ปิด blip ได้สำหรับคนที่โดนสุ่ม\nมีฟังชั่นกันเหลี่ยมเบื้องต้น เช่น ขึ้นรถเลือดลด | เหยียบน้ำเลือดลด | อยู่ใน blacklist Zone เลือดลด',
            price: 850,
            videoId: 'JF8abOtNEGM'
        },
        {
            label: "Banking",
            name: '🏛 ธนาคาร 🏛',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/banking.jpg?alt=media&token=3d773514-7bba-4e4a-b8b6-24fdd511cfd6',
            details: 'ระบบธนาคารมาใหม่ ลื่นๆ ไม่เหมือนใคร มีระบบอำนวยความสดวกมากมาย \nNo Sql \nสร้างกลุ่ม โอนเงินเป็นกลุ่ม \nสร้างรายการโปรด\nQrcode\nโอนได้ถึงแม้ผู้รับจะไม่อยู่ในเซิฟ',
            price: 1599,
            videoId: 'nV3uTaoBgYA'
        },
        //--------------------------------------------------------------------------------------------
        {
            label: "Crafting",
            name: '🔨 crafting 🔨',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/crafting.png?alt=media&token=a670db9e-0a8b-4451-a609-b75b153377da',
            details: 'ระบบโต๊ะคราฟคุณภาพสูง\nสร้างโต๊ะ และ แยกหมวดหมู่ได้ไม่จำกัด\nกำหนด UI ของแต่ละโต๊ะได้\nกำหนด Job ที่สามารถเปิดโต๊ะแต่ละตัวได้\nกำหนดการคราฟด้วยไอเท็มจากตู้เซฟ (เปิด-ปิด ได้)\nรองรับการอัพเกรดไอเท็ม หรือ อาวุธ\nกำหนดไอเท็มโบนัสเมื่อคราฟสำเร็จ และ ไม่สำเร็จได้\nกำหนดเงินเขียว เงินแดง ในการคราฟได้\nกำหนดจำนวนในการคราฟ และ สามารถกำหนดได้ว่า หากคราฟหลายชิ้น จะเช็คไอเท็ม   และเปอร์เซ็นครั้งเดียว หรือ แยกกันเช็คแต่ละชิ้นไปเลย\nกำหนดไอเท็มที่ต้องมี และ สามารถกำหนดได้ว่าจะลบไอเท็มที่ต้องมีหรือไม่หลังคราฟสำเร็จ',
            price: 1499,
            videoId: 'Jjj4R_7ZqX4'
        },
        {
            label: "Jail Manage",
            name: '⛓jail⛓',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/jail.png?alt=media&token=f41703b4-72c9-4c58-99b9-3f2db28db72d',
            details: 'ระบบเจล อำนวยความสะดวกสบายในการตั้งข้อหา และสามารถปรับแต่งธีม สี ได้ตามใจชอบ\nระบบส่งเข้าคุกและคำนวณเวลา - ค่าปรับ อัตโนมัติ มาพร้อมกับฟังก์ชั่น บำเพ็ญประโยชน์ [สั่งเก็บขยะ]\nเพิ่มคุก / ข้อหา คดี / ค่าปรับ / เวลา ได้ไม่จำกัด\nใส่บิลให้ผู้เล่น และปลดล็อคกุญแจมือ โดยอัตโนมัติ\nมีระบบ X2 X3 X4 บทลงโทษสำหรับหน่วยงานที่ทำความผิด\nมีฟังก์ชั่นปล่อยนักโทษออกจากคุกผ่านเมนู\nเลือกหน่วยงานที่จะใช้เมนูได้\nเลือกให้ใช้ไอเท็มลดระยะเวลาจำคุกได้\nแจ้งเวลาจำคุกแบบเรียลไทม์ (UI ฝั่งโจร)\nแสดงรายละเอียดของข้อหาชัดเจน รวมถึงค่าปรับและ เวลาจำคุกทั้งหมด (UI ฝั่งโจร)\nสามารถใช้ไอเท็มลดระยะเวลาจำคุกได้ [หากตร.อนุญาต] (UI ฝั่งโจร)\nแสดงจำนวนขยะที่ต้องเก็บ [หากตร. ให้บำเพ็ญประโยชน์] (UI ฝั่งโจร)',
            price: 1499,
            videoId: 'Xp8bLfVfaE0'
        },
        {
            label: "Gamble Tower",
            name: '♟Tower♟',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/gamble.png?alt=media&token=9985568c-d050-4db1-b99f-b053963b55d2',
            details: 'เกมส์หอคอยเสี่ยงดวง มากับ ui ที่สวยงาม\nมี 3 โหมดให้เลือกเล่น easy , medium , hard\nแต่ล่ะโหมดสามารถตั้ง % ความยาก/ผลตอบแทน ได้\nสามารถตั้งจุดเล่นได้ไม่จำกัด\nสามารตั้ง max จำนวนเงินเดิมพันได้',
            price: 499,
            videoId: 'lwQiJnCEnUA'
        },
        {
            label: "Report",
            name: '📝 report 📝',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/report.png?alt=media&token=ebe72499-e918-4829-b49f-dfca70b2f870',
            details: 'ระบบ Report เพิ่มความสะดวกสบายให้กับแอดมิน \nเลือกหมวดที่จะรายงานได้ [ แจ้งบัค , แจ้งผู้เล่น , แนะนำระบบ ]\nมีปุ่มให้ผู้เล่นแก้ปัญหาเฉพาะหน้า [ ตกแมพ , ตัวค้าง , ขาแข็ง ]\nระบบตรวจเช็คโพสต์ลง facebook ใส่ลิงค์แล้วรับของได้เลย',
            price: 600,
            videoId: 'QhVtE7bYuOo'
        },
        {
            label: "Jigsaw",
            name: '🧩jigsaw🧩',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/jigsaw.png?alt=media&token=1358f1e9-289c-4cb5-a44b-6ac802fdc322',
            details: 'ระบบจิ๊กซอว์ สุ่มเสกจิ๊กซอว์ให้ผู้เล่น 4 คน เมื่อรวบรวมครบ จนหมดเวลา หรือ นำไปแลกที่จุด จะเป็นผู้ชนะ\nสุ่มผู้เล่น 4 คนแล้วจะมี Jigsaw บนหัว (ถ้าหมดเวลา มีไม่ครบ 4 จะได้เศษจิ๊กซอว์)\nสามารถเปิดกิจกรรม ตามเวลา หรือ พิมพ์คำสั่งได้\nกำหนดของรางวัลใหญ่ได้ว่าจะ อยู่จนครบเวลา หรือ ไปแลกตรงจุดที่สุ่มไว้\nสามารถกำหนดกล่องจิ๊กซอว์ที่จะสุ่มเป็นของรางวัลใหญ่ได้เรื่อยๆ\nสามารถกำหนดรูปภาพทั่ง 4 รูป ของแต่ล่ะกล่องต่างกันได้\nสามารถกำหนดรางวัลใหญ่และเศษรางวัลได้',
            price: 1050,
            videoId: 'ptAH3sq-xjA'
        },
        {
            label: "Animal",
            name: '🐷 เลียงสัตว์ 🐷',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/animals.png?alt=media&token=59316258-f8d0-4d81-9bbf-ce6168f22293',
            details: 'ระบบเลี้ยงสัตว์ สุดน่ารักพร้อม ui บอกสถานะน้องๆ\nสามารถกำหนดได้ว่า จะเลี้ยงพร้อมกันได้กี่ตัว\nสามารถกำหนด zone ที่จะเลี้ยงได้\nสี UI สามารถปรับได้เองใน config\nมี UI บอกสถานะต่างๆ\nสามาถทำสุ่ม model สัตว์ที่จะ spawn ออกมาได้',
            price: 1000,
            videoId: '2mzqwiZabM0'
        },
        {
            label: "Billing",
            name: '🧾 billing 🧾',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/billng.png?alt=media&token=959afe2d-2362-4333-b20d-217618051bfd',
            details: 'ระบบบิล ที่แก้ปัญหาเรื่อง Server หน่วง และสามารถจ่ายบิล Auto ได้ด้วย\nสามารถตั้งจ่ายเงิน auto ได้\nสามารถโอนเงินด้วย ธนาคาร หรือ เงินสด\nมีแบ่งเงินกรมและคนส่งบิล\nจ่ายบิลได้แม้คนส่งบิลจะ ไม่ออนไลน์ อยู่ในเกม (เมื่อเข้าเกมจะยังได้เงินเหมือนเดิม)',
            price: 650,
            videoId: 'tr9iCYVh5BM'
        },
        {
            label: "Defend Pack",
            name: '🔰 Defend 🔰',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/defend.png?alt=media&token=63149f06-20bf-4647-b74a-6115e9c382d4',
            details: 'ระบบ รวม aed,pain,เกาะ (แถมระบบ ปรับดาเมจ ปรับเปอร์เซ็นคริแยกอาวุธ) !!\nสามารถปรับแต่งลูกเล่นของ Item ได้อย่างอิสระ\nสามารถเพิ่ม Item ได้อย่างไม่จำกัด\nบล็อคการกดใช้พร้อมกันของทั้ง 3 Item ได้ 100%\nมีระบบเกราะนาโน (ซ่อมเกราะอัตโนมัติ)\nกำหนด Job ที่จะให้ใช้ Item ได้\nบล็อคปุ่มกดได้ 100% ทั้งปุ่มแบบ Keymapping และ ControlPressed\nปรับแต่ง Prop ที่มือตอนกดใช้ Item ได้\nสามารถบล็อคการใช้ไอเท็มในโซนได้\nมี function สำหรับสร้างเงื่อนไขการใช้ Item ได้ด้วยต้นเอง\nแถมสคริปปรับดาเมจ สามารถเปิด-ปิดและปรับเปอร์เซ็นคริ ได้แยกอาวุธ',
            price: 800,
            videoId: '9se6T4phS6s'
        },
        {
            label: "Delivery",
            name: '🛵 delivery 🛵',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/delivery.png?alt=media&token=b5b0ba74-f6a2-45ec-8c42-fd53aa5dd48f',
            details: 'ระบบส่งของ คล้ายชีวิตจริง หมดปัญหาเรื่องเบื่องานฟามเดิมๆ\nสามารถตั้ง limit งานได้ว่า ทั่งเซิฟทำพร้อมกันได้กี่งาน.\nสามารถกำหนดชุดก่อนเริ่มทำงานได้.\nสามารถ blacklist หน่วยงานที่ไม่ให้ทำได้.\nสามารถเปลี่ยนสี GPS ได้.',
            price: 350,
            videoId: 'LDyGSslsHq4'
        },
        {
            label: "เคอร์ฟิว",
            name: '🚨 เคอร์ฟิว 🚨',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/curfew.png?alt=media&token=d2252a79-ab45-4454-a34f-ea414ff88da3',
            details: 'ระบบเคอร์ฟิว แบ่งใช้ได้หลายหน่วยงาน\nสามารถกำหนดหน่วยงาน ที่ติดตั้ง เคอฟิวได้.\nสามารถกำหนด สีของแต่ล่ะยศ.\nสามารถกำหนดรูปจัดการเคอร์ฟิวได้ (กำหนดตามการเขียนของผู้เขียน).',
            price: 650,
            videoId: 'ZtYAUSda5ac'
        },
        {
            label: "GPS",
            name: '📡 GPS 📡',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/gps.png?alt=media&token=0e4b3443-2553-40ff-8307-435dcec4f059',
            details: 'ระบบ GPS ติดตาม ผู้เล่น,รถ ช่วยตำรวจให้ทำงานง่ายขึ้น \nITEM ติดตั้ง GPS ให้ คน , รถ.\nITEM ถอนการติดตั้ง GPS,\nGPS สำหรับ jail (กรณีผู้เล่นติดคุกแล้วหนีออกจากคุกจะมี GPS ติดตามตัวและแจ้่งเตือนหน่วยงานตำรวจ)',
            price: 400,
            videoId: 'DHiage1lABM'
        },
        {
            label: "Shop",
            name: '🛒 SHOP 🛒',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/shop.png?alt=media&token=26047b3f-fe6c-40d9-aba3-fbccc3cd4210',
            details: 'ระบบร้านค้าดีไซน์โมเดิร์น และ สามารถปรับแต่ง UI ได้เองตามที่ทางเซิฟต้องการ\nมีกันโปรในตัว\nใน 1 ร้านสามารถเปิดจุดได้ไม่จำกัด\n ใน 1 ร้าน สามารถกำหนดหมวดหมู่เพิ่มเติมได้\nกำหนด Job หรือ Item VIP กำหรับเปิดร้านค้าได้\nกำหนด Job หรือ Item VIP ในการเปิดหมวดหมู่ในร้านได้\nผู้เล่นสามารถจ่ายเงินในการซื้อได้ทั้งเงินเขียว เงินแบงค์ หรือ เงินแดงได้\nลงขายได้ทั้ง Item และ Weapon ทำหนดหน่วยเงินในการซื้อ Item ได้ในแต่ละหมวดหมู่\nใช้ได้ทั้ง Server RP และ Training (สำหรับ Training สามารถ Config กดปุ่มเปิดร้านค้าได้)',
            price: 800,
            videoId: 'v1vzO3_2FTk'
        },
        {
            label: "ฝากรถ",
            name: '🚙ฝากรถ🚙',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/car.png?alt=media&token=2639d54d-1513-4636-b21f-ec215c90adb0',
            details: 'ระบบฝากรถ เปิดท้ายรถได้ และ โชว์ ป้ายทะเบียน , ค่าน้ำมัน , ค่าเสียหายของรถ\nสามารถ สร้าง เบิก/เก็บ รถได้ไม่จำกัด\nสามารถ สร้าง Blips เพิ่มได้ไม่จำกัด\nในกรณีที่ชื่อรถไม่ตรงกับชื่อรถตอนเสก มีฟังชั่น เปลี่ยนชื่อรถให้\nสามารถตั้งได้ว่าจะฝากรถตัวเองอย่างเดียวเท่านั้น หรือ จะรถคนอื่นก็ได้\nสามารถปรับได้ว่าจุดแต่ล่ะจุดไม่เชื่อมกัน [ฝากรถที่จุด A ก็ต้องไปเก็บรถที่จุด A ไม่สามารถไปเบิกจุด B ได้]',
            price: 550,
            videoId: 'mQk41KmNlIU'
        },
        {
            label: "ขายยาสุ่ม NPC",
            name: '💊 ขายยา 💊',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/npc.png?alt=media&token=63cd338f-e904-40a8-a76c-742acf484cd7',
            details: ' ระบบขายยา สามารถตั้งพิกัดใน MAP ได้ว่าจะสุ่มเกิดตรงไหนบ้าง\nสามารถตั้งได้ว่าจะ ลบหรือไม่ลบ บัตรขาย ตอนกดใช้หรือไม่\nสามารถกำหนดได้ว่าจะขายอะไร และสุ่มราคาขายต่อชิ้น\nสามารถตั้งได้ว่าถ้าขายแล้วพลาดโดน reject แต่เพื่อนอุ้มหนีออกจากจุดที่โดน freeze จะให้ตายด้วยวิธีไหน\nมี Webhook discord แจ้งเตือนตอนขายได้\nกำหนดตำรวจได้\nสามารถกำหนด ข้อความ,ตำแหน่ง ข้อความได้หมด\nสามารถเปลี่ยน prop , npc ได้\nสามารถเปลี่ยน แจ้งเตือนเป็นของตัวเองได้\nเปลี่ยน font ได้ สี ได้',
            price: 600,
            videoId: 'ji8gHEvcKUc'
        },
        {
            label: "All Black-job",
            name: '🧨 All BlackJobs 🧨',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/allback.png?alt=media&token=4bbfc1c9-6148-4d6b-8b93-348bfe405e61',
            details: 'ระบบงานดำ คูลดาวทั่งเซิฟ resmon วิ่งน้อยสุดๆ\nรองรับทั่ง 1.1 / 1.2\nสามารถทำเป็นงาน จกปูน , สายไฟ , และอื่นๆ ได้ในสคริปเดียว\nresmon วิ่ง 0.00 - 0.04 [เฉพาะเข้าไปใกล้] ลื่นสุดๆ\nมี Cooldown ทั่งเซิฟ ออกเข้าใหม่ก็ยังมีผลอยู่\nมี webhook เห็นรูปบอก\nมินิเกมสามารถเปลี่ยนเป็น มินิเกมอื่นได้',
            price: 600,
            videoId: '60y8zm57tVI'
        },
        {
            label: "ซ่อมไฟ",
            name: '⚡️ ซ่อมไฟ ⚡️',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/Fire_repair.png?alt=media&token=d21c6967-c54b-464b-a4c6-80f524692424',
            details: 'ระบบซ่อมไฟ ปรับเปลี่ยน UI และเปลี่ยนการเขียนสคริปต์ให้ลื่นขี้น\nสุ่มเวลาที่ไฟจะดับทั่งเมือง\nสามารถสุ่มจุดได้ว่า จะให้ซ่อมตรงไหนบ้าง\nสามารถตั้งได้ว่าถ้าจุดเกิดออกมาแล้วจะให้ ซ่อมกี่ที่\nใครในเซิฟจะซ่อมก็ได้จุดนั้นก็จะหายไป\nมี webhook บอกว่าใคร ซ่อม และใครได้โบนัสอะไร\nสามารถตั้งของที่จะได้รับได้ weapon,item,money,black_money\nมีมินิเกมสามารถ เปิด ปิด ได้\n ถ้าไม่มีใครไปซ่อมจนเลย เวลาที่กำหนด ไฟถนน จะกลับมาติดเอง',
            price: 500,
            videoId: 'wNa4-brNvj4'
        },
        {
            label: "Quest",
            name: '📬Quest',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/quest.png?alt=media&token=32fb0704-2440-45f5-a8de-3b4a0db62809',
            details: 'ระบบเควส ให้การฟามมีความหมายมากยิ่งขึ้นใช้งานง่ายแค่วาง event \nป้องกันการฉีด Event 100%\nระบบเควสแบบแยกหมวดหมู่\nสามารถสร้างหมวด พร้อมจุดรับเควสได้มากกว่า 1 จุด\nในแต่ละหมวดสร้างเควสแตกต่างกันได้ เช่น จุด 1 รับเควสงานขวา | จุด 2 รับเควสงานดำ\nมีปุ่ม เปิด-ปิด ซ่อน UI\nกำหนด limit เควสที่จะได้รับ\nกำหนดรูปแบบ UI ของแต่ละจุดเองได้\nของรางวัล 2 แบบ สามารถใส่โบนัสรางวัลแยกเป็นเควสได้\nกำหนดรางวัลเป็น Item Money Blackmoney ได้',
            price: 550,
            videoId: '-T4ZZ3Q4yvI'
        },
        {
            label: "ตกปลา",
            name: '🦈ตกปลา🦈',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/fish.png?alt=media&token=d5a78bfb-360f-4266-802d-60512c686584',
            details: 'ระบบตกปลา เป็นได้ทั้งงาน ดำ - ขาว ไม่หน่วงเซิร์ฟ\nมีเรือให้ยืม [ปิด/เปิด] ได้\nเลือกได้ว่าจะไปไกลๆทะเลถึงตกได้ หรือ จะกำหนดจุดเอง\nสามารถ เปิด-ปิด หรือ เปลี่ยน มินิเกมได้ ใน config\nสามารถเปิดเช็คตำรวจได้\nถ้าตกได้ฉลามจะยกเลิกตกไปแล้วจะมี Event ให้ไปขายฉลาม [สามารถตั้งได้ว่าจะแจ้งตำรวจตอนไหน]\nสามารถ เปิด-ปิด blips ขายฉลามได้\nมี webhook',
            price: 500,
            videoId: 'qlLHLtIZXDU'
        },
        {
            label: "Allrent",
            name: '⏰เช่า⏰',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/allrent.png?alt=media&token=8a07c365-ae37-45f1-8d26-3b862eefd5da',
            details: 'ระบบเช่า รวม สคริป เช่า อาวุธ , item , รถ เข้าไว้ด้วยกัน\nสามารถ เช่าได้ทั่ง  รถ , อาวุธ , Items\nสามารถพิมพ์คำสั่งแอดของให้ผู้เล่นได้\nสามารถตั้งจุดรับบัตรผู้เล่นใหม่ได้\nมี webhook สามารถเช็คได้ว่าใครเช่า Item อะไรไป\nสามารถกำหนดรถและวันที่จะให้เช่าได้\nสามารถกำหนดอาวุธและวันที่จะให้เช่าได้\n สำหรับ item และ อาวุธ ไม่ควรให้ trade ให้คนอื่นได้',
            price: 500,
            videoId: 'mNERBW-ncI4'
        }
    ],
    news: { //*NOTE  - สินค้าใหม่ (มีแค่ 3 ตัวเท่านั้น)
        first: {
            label: "Jail Manage",
            name: '⛓jail⛓',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/jail.png?alt=media&token=f41703b4-72c9-4c58-99b9-3f2db28db72d',
            details: 'ระบบเจล อำนวยความสะดวกสบายในการตั้งข้อหา และสามารถปรับแต่งธีม สี ได้ตามใจชอบ\nระบบส่งเข้าคุกและคำนวณเวลา - ค่าปรับ อัตโนมัติ มาพร้อมกับฟังก์ชั่น บำเพ็ญประโยชน์ [สั่งเก็บขยะ]\nเพิ่มคุก / ข้อหา คดี / ค่าปรับ / เวลา ได้ไม่จำกัด\nใส่บิลให้ผู้เล่น และปลดล็อคกุญแจมือ โดยอัตโนมัติ\nมีระบบ X2 X3 X4 บทลงโทษสำหรับหน่วยงานที่ทำความผิด\nมีฟังก์ชั่นปล่อยนักโทษออกจากคุกผ่านเมนู\nเลือกหน่วยงานที่จะใช้เมนูได้\nเลือกให้ใช้ไอเท็มลดระยะเวลาจำคุกได้\nแจ้งเวลาจำคุกแบบเรียลไทม์ (UI ฝั่งโจร)\nแสดงรายละเอียดของข้อหาชัดเจน รวมถึงค่าปรับและ เวลาจำคุกทั้งหมด (UI ฝั่งโจร)\nสามารถใช้ไอเท็มลดระยะเวลาจำคุกได้ [หากตร.อนุญาต] (UI ฝั่งโจร)\nแสดงจำนวนขยะที่ต้องเก็บ [หากตร. ให้บำเพ็ญประโยชน์] (UI ฝั่งโจร)',
            price: 1499,
            videoId: 'Xp8bLfVfaE0'
        },
        secound: {
            label: "Crafting",
            name: '🔨 crafting 🔨',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/crafting.png?alt=media&token=a670db9e-0a8b-4451-a609-b75b153377da',
            details: 'ระบบโต๊ะคราฟคุณภาพสูง\nสร้างโต๊ะ และ แยกหมวดหมู่ได้ไม่จำกัด\nกำหนด UI ของแต่ละโต๊ะได้\nกำหนด Job ที่สามารถเปิดโต๊ะแต่ละตัวได้\nกำหนดการคราฟด้วยไอเท็มจากตู้เซฟ (เปิด-ปิด ได้)\nรองรับการอัพเกรดไอเท็ม หรือ อาวุธ\nกำหนดไอเท็มโบนัสเมื่อคราฟสำเร็จ และ ไม่สำเร็จได้\nกำหนดเงินเขียว เงินแดง ในการคราฟได้\nกำหนดจำนวนในการคราฟ และ สามารถกำหนดได้ว่า หากคราฟหลายชิ้น จะเช็คไอเท็ม   และเปอร์เซ็นครั้งเดียว หรือ แยกกันเช็คแต่ละชิ้นไปเลย\nกำหนดไอเท็มที่ต้องมี และ สามารถกำหนดได้ว่าจะลบไอเท็มที่ต้องมีหรือไม่หลังคราฟสำเร็จ',
            price: 1499,
            videoId: 'Jjj4R_7ZqX4'
        },
        third: {
            label: "Allrent",
            name: '⏰เช่า⏰',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/allrent.png?alt=media&token=8a07c365-ae37-45f1-8d26-3b862eefd5da',
            details: 'ระบบเช่า รวม สคริป เช่า อาวุธ , item , รถ เข้าไว้ด้วยกัน\nสามารถ เช่าได้ทั่ง  รถ , อาวุธ , Items\nสามารถพิมพ์คำสั่งแอดของให้ผู้เล่นได้\nสามารถตั้งจุดรับบัตรผู้เล่นใหม่ได้\nมี webhook สามารถเช็คได้ว่าใครเช่า Item อะไรไป\nสามารถกำหนดรถและวันที่จะให้เช่าได้\nสามารถกำหนดอาวุธและวันที่จะให้เช่าได้\n สำหรับ item และ อาวุธ ไม่ควรให้ trade ให้คนอื่นได้',
            price: 500,
            videoId: 'mNERBW-ncI4'
        }
    },
    advice: { //*NOTE  - สินค้าแนะนำ (มีแค่ 3 ตัวเท่านั้น)
        first: {
            label: "Banking",
            name: '🏛 ธนาคาร 🏛',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/banking.jpg?alt=media&token=3d773514-7bba-4e4a-b8b6-24fdd511cfd6',
            details: 'ระบบธนาคารมาใหม่ ลื่นๆ ไม่เหมือนใคร มีระบบอำนวยความสดวกมากมาย \nNo Sql \nสร้างกลุ่ม โอนเงินเป็นกลุ่ม \nสร้างรายการโปรด\nQrcode\nโอนได้ถึงแม้ผู้รับจะไม่อยู่ในเซิฟ',
            price: 1599,
            videoId: 'nV3uTaoBgYA'
        },
        secound: {
            label: "Shop",
            name: '🛒 SHOP 🛒',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/shop.png?alt=media&token=26047b3f-fe6c-40d9-aba3-fbccc3cd4210',
            details: 'ระบบร้านค้าดีไซน์โมเดิร์น และ สามารถปรับแต่ง UI ได้เองตามที่ทางเซิฟต้องการ\nมีกันโปรในตัว\nใน 1 ร้านสามารถเปิดจุดได้ไม่จำกัด\n ใน 1 ร้าน สามารถกำหนดหมวดหมู่เพิ่มเติมได้\nกำหนด Job หรือ Item VIP กำหรับเปิดร้านค้าได้\nกำหนด Job หรือ Item VIP ในการเปิดหมวดหมู่ในร้านได้\nผู้เล่นสามารถจ่ายเงินในการซื้อได้ทั้งเงินเขียว เงินแบงค์ หรือ เงินแดงได้\nลงขายได้ทั้ง Item และ Weapon ทำหนดหน่วยเงินในการซื้อ Item ได้ในแต่ละหมวดหมู่\nใช้ได้ทั้ง Server RP และ Training (สำหรับ Training สามารถ Config กดปุ่มเปิดร้านค้าได้)',
            price: 800,
            videoId: 'v1vzO3_2FTk'
        },
        third: {
            label: "Jail Manage",
            name: '⛓jail⛓',
            img: 'https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/jail.png?alt=media&token=f41703b4-72c9-4c58-99b9-3f2db28db72d',
            details: 'ระบบเจล อำนวยความสะดวกสบายในการตั้งข้อหา และสามารถปรับแต่งธีม สี ได้ตามใจชอบ\nระบบส่งเข้าคุกและคำนวณเวลา - ค่าปรับ อัตโนมัติ มาพร้อมกับฟังก์ชั่น บำเพ็ญประโยชน์ [สั่งเก็บขยะ]\nเพิ่มคุก / ข้อหา คดี / ค่าปรับ / เวลา ได้ไม่จำกัด\nใส่บิลให้ผู้เล่น และปลดล็อคกุญแจมือ โดยอัตโนมัติ\nมีระบบ X2 X3 X4 บทลงโทษสำหรับหน่วยงานที่ทำความผิด\nมีฟังก์ชั่นปล่อยนักโทษออกจากคุกผ่านเมนู\nเลือกหน่วยงานที่จะใช้เมนูได้\nเลือกให้ใช้ไอเท็มลดระยะเวลาจำคุกได้\nแจ้งเวลาจำคุกแบบเรียลไทม์ (UI ฝั่งโจร)\nแสดงรายละเอียดของข้อหาชัดเจน รวมถึงค่าปรับและ เวลาจำคุกทั้งหมด (UI ฝั่งโจร)\nสามารถใช้ไอเท็มลดระยะเวลาจำคุกได้ [หากตร.อนุญาต] (UI ฝั่งโจร)\nแสดงจำนวนขยะที่ต้องเก็บ [หากตร. ให้บำเพ็ญประโยชน์] (UI ฝั่งโจร)',
            price: 1499,
            videoId: 'Xp8bLfVfaE0'
        }
    },
}



export default listScripts;