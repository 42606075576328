import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/styleMobile.scss'
import { faBahtSign, faTerminal, faXmark, faCircleExclamation, faSpinner, faImage } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../storeSlice/contextSlice/Context';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import listScripts from '../models/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment-timezone';
import config from '../models/config';

const ViewProduct = () => {

    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState(false);
    const [pay, setPay] = useState(true);
    const [viewScript, setViewScript] = useState(null);
    const [click, setClick] = useState(false)
    const [checkQr, setCheckQr] = useState(true)
    const [payment, setPayment] = useState('qrCode');
    const [wallet, setwallet] = useState('');
    const dispatch = useDispatch()
    const data = useSelector((state) => state)
    const scriptAlls = Object.values(listScripts.all);
    const scriptNews = Object.values(listScripts.news);
    const scriptAdvice = Object.values(listScripts.advice);
    const [imgQrCode, setImgQrCode] = useState(null);
    const [time, setTime] = useState(0);
    const [idPay, setIdPay] = useState(0);
    const [IP, setIP] = useState('');
    const [IPWallet, setIPWallet] = useState('');
    let index = 0;
    const dataView = localStorage.getItem("dataView");
    const scrip = JSON.parse(dataView)

    const success = () => toast.success("แจ้งชำระเงินสำเร็จ")
    const warnQr = () => toast.warn("กรุณาโอนเงิน หรือกรอก IP ก่อนแจ้งชำระเงิน")
    const warnWallet = () => toast.warn("กรุณาใส่ลิ้งค์อั่งเปา หรือกรอก IP ก่อนแจ้งชำระเงิน")


    const handleLogin = () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=440504729261572106&redirect_uri=https%3A%2F%2Fmrb-developer.com&response_type=code&scope=identify%20connections%20email';
    };

    useEffect(() => {
        setLoading(true)
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            axios.get(`${config.URL}/api/callback?code=${code}`)
                .then((response) => {
                    const user = JSON.parse(response.data.data);
                    dispatch(login(user))
                })
                .catch((error) => {
                });
        }

        if (data.clientID === 0) {
            setLogin(true)
            setPay(false)
        }

        if (!data.accessToken) {
            dispatch(logout())
        } 

        if (!scrip.label) {
            return
        }
        if (scrip.label === 'scriptAll') {
            index = scrip.index
            setViewScript(scriptAlls[index])
            setLoading(false)
        } else if (scrip.label === 'scriptNew') {
            index = scrip.index
            setViewScript(scriptNews[index])
            setLoading(false)
        } else if (scrip.label === 'scriptIntroduce') {
            index = scrip.index
            setViewScript(scriptAdvice[index])
            setLoading(false)
        }
        let interval = null;

        if (time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };

    }, [data.clientID, time]);

    const handleWallet = (e) => {
        const wallet = e.target.value;
        setwallet(wallet)
    }

    const handleIP = (e) => {
        const IP = e.target.value;
        const filteredIP = IP.replace(/[^\d.]/g, '');
        setIP(filteredIP)
    }
    const handleIPWallet = (e) => {
        const IP = e.target.value;
        const filteredIP = IP.replace(/[^\d.]/g, '');
        setIPWallet(filteredIP)
    }

    const payScript = () => {
        setClick(!click)
        setLoading(true)
        const API = `${config.URL}/createQrCode`

        axios.post(API, {
            "clientID": data.clientID,
            "nameScript": viewScript.name,
            "amount": viewScript.price
        })
            .then((response) => {
                if (response.data.data === "{}") {
                    setCheckQr(false)
                    setLoading(false)
                } else {
                    setCheckQr(true)
                    const data = JSON.parse(response.data.data);
                    const imageUrl = data.qr_image_base64;
                    const imgQrCodeBase64 = `data:image/png;base64,${imageUrl}`;
                    setImgQrCode(imgQrCodeBase64)
                    const time = data.time_out / 60

                    const totalSeconds = Math.floor(time * 60);
                    setTime(totalSeconds)
                    setIdPay(data.id_pay)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            });
    }

    //? การ formatted time
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const Time = formattedMinutes + ' : ' + formattedSeconds;

    const qrCode = (click) => { setPayment(click) }
    const Wallet = (click) => { setPayment(click) }



    const payOrder = () => {
        if (payment === 'qrCode') {
            setLoading(true)
            const API = `${config.URL}/verifyQrCode`
            if (!IP || Time === '00:00') {
                warnQr()
                return
            } else {
                axios.post(API, {
                    "id_pay": idPay,
                    "ip": IP
                })
                    .then((response) => {
                        setClick(false)
                        success()
                        setLoading(false)
                        toast.info("ติดต่อรับสคริปต์ได้ที่ Discord")
                    })
                    .catch((error) => {
                        setLoading(false)
                    });
            }
        } else if (payment === 'wallet') {
            setLoading(true)
            const API = `${config.URL}/verifyTrueMoneyWallet`
            if (!IPWallet) {
                warnWallet()
                return
            } else {
                axios.post(API, {
                    "link": wallet,
                    "ip": IPWallet,
                    "clientID": data.clientID,
                    "nameScript": viewScript.name
                })
                    .then((response) => {
                        if (response.data.success === false) {
                            const message = response.data.message
                            const setmessage = message.split('Error : ')
                            setLoading(false)
                            toast.warn(setmessage[1])
                        } else {
                            setClick(false)
                            success()
                            setLoading(false)
                            toast.info("ติดต่อรับสคริปต์ได้ที่ Discord")
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                    });
            }
        }
    }
    const closePay = () => {
        setIPWallet('')
        setIP('')
        setwallet('')
        setClick(false)
    }


    return (
        <div className='from-view-product'>
            {viewScript && (
                <div className="grid-view pt-[5vw] px-[10vw] pb-[30px]">
                    {loading && (
                        <div className='fixed gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50 text-white'>
                            <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin'></FontAwesomeIcon>
                            Load...
                        </div>
                    )}
                    <div className='flex justify-between items-center'>
                        <span>
                            <p className="text-[3vw] text-white font-bold">{viewScript.label}</p>
                            <div className='card-price w-[6vw] p-[.2vw] pr-[.6vw] text-center bg-gradient-to-r from-violet-950 to-violet-900 rounded-[.5vw] shadow-lg'>
                                <p className='pt-[.15vw] text-[1.4vw] font-bold text-white'>{Number(viewScript.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                            </div>
                        </span>
                        <div className='card-pay p-[.2%] text-center cursor-pointer bg-white rounded-[.5vw] backdrop-blur-sm bg-white/30'>
                            {pay && (
                                <p onClick={payScript} className='text-[1.5vw] text-white font-bold pt-[.2vw] px-[1vw] bg-gradient-to-r from-violet-950 to-violet-900 rounded-[.5vw] shadow-lg transition ease-in-out delay-75 hover:scale-[.9] duration-300'>
                                    ซื้อตอนนี้!</p>
                            )}
                            {login && (
                                <p onClick={handleLogin} className='text-[1.5vw] text-white font-bold pt-[.2vw] px-[1vw] bg-gradient-to-r from-violet-950 to-violet-900 rounded-[.5vw] shadow-lg transition ease-in-out delay-75 hover:scale-[.9] duration-300'>
                                    เข้าสู่ระบบตอนนี้!</p>
                            )}

                        </div>
                    </div>

                    <div className='view-script flex items-start gap-[3vw] p-[1vw] mt-[1vw] justify-center pb-[20px]'>
                        <div className='relative top-[3vw] p-[5px] bg-white rounded-[.5vw] backdrop-blur-sm bg-white/30'>
                            <img className='w-[28vw] h-[18vw] rounded-[.5vw]' src={viewScript.img} />
                        </div>
                        <div className='video-player relative'>
                            <iframe
                                src={`https://www.youtube.com/embed/${viewScript.videoId}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className='w-[560px] h-[315px] rounded-[.5vw] mr-[auto] ml-[auto]'
                            />
                            <div className='card-description w-[33.5vw] h-auto rounded-[.5vw] bg-white backdrop-blur-sm bg-white/10 pb-[3vw] mt-[1vw]'>
                                <div className='w-[8.2vw] p-[.2vw] pr-[.6vw] text-right bg-gradient-to-r from-violet-950 to-violet-900 rounded-r-lg relative right-[.5vw] top-[1vw] shadow-lg'>
                                    <p className='text-[1.2vw] font-bold text-white'>Description</p>
                                </div>
                                <div className='relative top-[1.5vw] left-[1vw] w-[31vw] text-white p-[.5vw]'>
                                    {viewScript.details.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {index === 0 && (
                                                <div>
                                                    <p className='flex gap-[1vw] pt-[.5vw]'>
                                                        {line}
                                                    </p>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className='relative top-[1.5vw] left-[1vw] w-[31vw] text-white p-[.5vw]'>
                                    {viewScript.details.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {index !== 0 && (
                                                <p className='flex gap-[1vw] pt-[.5vw]'>
                                                    <FontAwesomeIcon className='icon-terminal' icon={faTerminal}></FontAwesomeIcon>
                                                    {line}
                                                </p>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={click ? 'pay-script active' : 'pay-script'} >
                        <div className='header-pay flex items-center justify-between p-[10px]'>
                            <p className='text-[15px] p-[5px] px-[15px] bg-black backdrop-blur-sm bg-white/10 rounded-[5px]'>สั่งซื้อ</p>
                            <FontAwesomeIcon icon={faXmark}
                                onClick={closePay}
                                className='icon-close-pay cursor-pointer z-10 p-[7px] rounded-[5px] hover:bg-red-400 transition ease-in-out delay-75 duration-300  backdrop-blur-sm bg-white/10'></FontAwesomeIcon>
                        </div>
                        <hr />
                        <div className='pay-details p-[20px] px-[30px] pt-[30px]'>
                            <p className='text-[13px]'>ช่องทางการชำระ</p>
                            <div className='btn-choose-pay flex justify-between items-center p-[20px] px-[50px]'>
                                <button onClick={() => qrCode('qrCode')} className={payment === 'qrCode' ? 'payment active' : 'payment'} type="">QR Code</button>
                                <p>|</p>
                                <button onClick={() => Wallet('wallet')} className={payment === 'wallet' ? 'payment active' : 'payment'} type="">อั่งเปา</button>
                            </div>

                            <div className='card-choose-pay bg-white backdrop-blur-sm bg-white/10 p-[20px] rounded-[15px] w-[100%] h-[11vw] relative'>
                                {payment === 'qrCode' && <>
                                    {checkQr ? (
                                        <div className='card-img-qr w-[52%] max-w-[100%] h-[100%] mr-[auto] ml-[auto] relative z-10 '>
                                            <img className='img-qr w-[100%] h-[100%] mr-[auto] ml-[auto] transition ease-in-out delay-75 duration-300 object-cover object-top' src={imgQrCode} alt="" />
                                        </div>
                                    ) : (
                                        <div className='card-img-qr w-[52%] max-w-[100%] h-[100%] mr-[auto] ml-[auto] relative z-10 border border-zinc-600 text-center'>
                                            <FontAwesomeIcon icon={faImage} className='text-[29px] text-gray-400 mt-[30%]'></FontAwesomeIcon>
                                            <p className='text-[12px]'>No Image Found</p>
                                        </div>
                                    )}
                                </>}
                                {payment === 'wallet' &&
                                    <img className='img-wallet w-[50%] mr-[auto] ml-[auto] transition ease-in-out delay-75 duration-300' src="https://cdn.discordapp.com/attachments/796649426214977547/1133818119983472641/Pngtreeangpao_lunar_chinese_day_vector_8956764.png" alt="" />
                                }
                            </div>
                            {payment === 'qrCode' &&
                                <>
                                    <p className='text-name-script font-bold text-[15px] text-center mt-[10px]'>{Time}</p>
                                    <span className='flex gap-[10px] items-center p-[4px] pl-[10px] bg-white backdrop-blur-sm bg-white/20 border-[1px] border-yellow-500 rounded-[10px] mt-[10px]'>
                                        <FontAwesomeIcon icon={faCircleExclamation} className='text-yellow-500'></FontAwesomeIcon>
                                        <p className='text-[13px] pt-[1px]'>เมื่อหมดเวลา หรือไม่มี QR Code กรุณาทำการกดสั่งซื่อใหม่</p>
                                    </span>
                                    <span className='w-[100%] flex justify-center mt-[15px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={IP}
                                            onChange={handleIP}
                                            placeholder='IP Server'
                                            className='h-[30px] w-[50%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                </>
                            }
                            {payment === 'wallet' &&
                                <>
                                    <span className='w-[100%] flex justify-center mt-[10px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={wallet}
                                            onChange={handleWallet}
                                            placeholder='ลิงค์อั่งเปา'
                                            className='h-[30px] w-[80%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                    <span className='w-[100%] flex justify-center mt-[15px]'>
                                        <input
                                            type="text"
                                            name=""
                                            value={IPWallet}
                                            onChange={handleIPWallet}
                                            placeholder='IP Server'
                                            className='h-[30px] w-[50%] rounded-[5px] focus:outline-none px-[10px] text-black text-[15px]' />
                                    </span>
                                    <span className='flex gap-[10px] items-center p-[4px] pl-[10px] bg-white backdrop-blur-sm bg-white/20 border-[1px] border-yellow-500 rounded-[10px] mt-[10px]'>
                                        <FontAwesomeIcon icon={faCircleExclamation} className='text-yellow-500'></FontAwesomeIcon>
                                        <p className='text-[13px] pt-[1px]'>กรุณาเติมอั่งเปาตามจำนวนที่ต้องชำระเงิน</p>
                                    </span>
                                </>
                            }

                        </div>

                        <div className='pay-details-script px-[50px] pt-[10px]'>
                            <p className='text-[13px]'>script</p>
                            <div className='flex justify-between items-center'>
                                <p className='text-name-script font-bold text-[20px]'>{viewScript.label}</p>
                                <p className='text-[20px]'>{Number(viewScript.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                            </div>
                        </div>
                        <div className='p-[20px] px-[30px]'>
                            <button type=""
                                onClick={payOrder}
                                className="rounded-md bg-gradient-to-r from-sky-500 to-indigo-500 px-2 py-1 text-xs text-white ring-1 ring-inset ring-pink-700/10 w-[100%] h-[30px] hover:bg-red-700 hover:scale-[.99] transition delay-75 duration-300 ease-in-out"
                            ><p className='text-[20px] font-bold'>แจ้งชำระเงิน</p></button>
                        </div>
                    </div>
                    <ToastContainer theme="dark" />
                </div>

            )}
        </div>
    )
}

export default ViewProduct;